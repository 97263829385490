.App {
  --bg-color: white;
  --text-color: black;
  --hover-color: gray;

  text-align: left;
  height: 100%;
  display: flex;
  justify-content: center;
  color: var(--text-color);
  /* background: url('./images/backgroundTile.jpeg') repeat fixed center; */
  padding: 28px;
}

body, html {
  background: url('./images/bkgd.png') repeat fixed top;
  background-size: 100%;
  /* background: url(https://gdurl.com/h0tk) no-repeat fixed center; */
  height: auto;
  font-family: 'Nunito', sans-serif;
}

h1 {
  font-family: 'Merriweather', serif;
  font-size: 35px;
  font-weight: 700;
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 23px;
}

h3 {
  margin: 0;
}

.link {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
  display: inline;
}

.link:hover {
  color: var(--hover-color);
}

.main {
  background-color: var(--bg-color);
  display: flex;
  flex-flow: column wrap;
  width: 320px;
  padding: 30px;
  overflow: auto;
  box-shadow: 10px -10px var(--text-color);
}

.button {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--bg-color);
  background-color: var(--text-color);
  padding: 8px 15px;
  border-radius: 5px;
  margin: 5px 0;
}

.button:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

/* home page */
.list-links {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: 800;
  padding: 10px 0;
  color: var(--text-color);
  text-decoration: none;
  display: flex;
}

.list-links:hover {
  color: var(--hover-color);
}

/* payment icons (in HowToPay.js) */
.paymentIcons {
  width: 70px;
  mix-blend-mode: luminosity;
}

.paymentIcons:hover {
  cursor: pointer;
  filter: brightness(85%);
}

/* social icons */
.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  background: var(--text-color);
  color: var(--bg-color);
}

.social-icons {
  padding: 9px;
  width: 29px;
  height: 29px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  background: var(--text-color);
  color: var(--bg-color);
}

.social-icons:hover {
  background-color: var(--hover-color);
}

.svg-social-icon {
  filter: invert(90%) sepia(4%) saturate(1245%) hue-rotate(308deg) brightness(100%) contrast(103%);
}